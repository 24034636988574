<template lang="pug">
    div
        //- Filtros de colores
        color-filter(
            v-model="value.colors_states" 
            :schema="colorStateSchema" 
            title="Colores por estado"
            :validation="validateStates"
        )
            template(slot="form" slot-scope="model")
                v-flex(xs12 sm4)
                    v-select(
                        v-model="model.model.country"
                        :items='countries',
                        item-value="uuid",
                        item-text="name", 
                        :label='$t("País")', 
                        append-icon="keyboard_arrow_down", 
                        autocomplete
                        :filter="vFilter"
                        :return-object="true",
                        @input="states_s=[],rfetchState(model.model.country.uuid)"
                    )
                v-flex(xs12 sm4 v-if="Object.keys(model.model.country).length")
                    v-select(
                        v-model="model.model.state"
                        :items='states_s',
                        item-value="uuid",
                        item-text="name", 
                        :label='$t("Estado/Departamento/Provincia")', 
                        append-icon="keyboard_arrow_down", 
                        autocomplete
                        :filter="vFilter"
                        :return-object="true",
                    )
            template(slot="list" slot-scope="item")
                | {{ item.item.country.name }} - {{ item.item.state.name }}

        color-filter(
            v-model="value.colors_cities"
            title="Colores por ciudad"
            :schema="colorCitySchema"
            :validation="validateCities"
        )
            template(slot="form" slot-scope="model")
                v-flex(xs12 sm4)
                    v-select(
                        v-model="model.model.country"
                        :items='countries',
                        item-value="uuid",
                        item-text="name", 
                        :label='$t("País")', 
                        append-icon="keyboard_arrow_down", 
                        autocomplete
                        :filter="vFilter"
                        :return-object="true"
                        @input="states_c=[],rfetchState(model.model.country.uuid, 'states_c')"
                    )
                v-flex(xs12 sm4 v-if="Object.keys(model.model.country).length")
                    v-select(
                        v-model="model.model.state"
                        :items='states_c',
                        item-value="uuid",
                        item-text="name", 
                        :label='$t("Estado/Departamento/Provincia")', 
                        append-icon="keyboard_arrow_down", 
                        autocomplete
                        :filter="vFilter"
                        :return-object="true"
                        @input="cities=[],rfetchCity(model.model.state.uuid)"
                    )
                v-flex(xs12 sm4 v-if="Object.keys(model.model.state).length")
                    v-select(
                        v-model="model.model.city"
                        :items='cities',
                        item-value="uuid",
                        item-text="name",
                        :label='$t("Ciudad/Municipio")',
                        append-icon="keyboard_arrow_down",
                        autocomplete
                        :filter="vFilter"
                        :return-object="true"
                    )
            template(slot="list" slot-scope="item")
                | {{ item.item.country.name }} - {{ item.item.state.name }} - {{ item.item.city.name }}

        color-filter(
            v-model="value.colors_zones"
            title="Colores por zona"
            :schema="colorZoneSchema"
            :validation="validateZones"
        )
            template(slot="form" slot-scope="model")
                v-flex(xs12 sm4)
                    v-select(
                        v-model="model.model.country"
                        :items='countries',
                        item-value="uuid",
                        item-text="name", 
                        :label='$t("País")', 
                        append-icon="keyboard_arrow_down", 
                        autocomplete
                        :filter="vFilter"
                        :return-object="true"
                        @input="states_z=[],rfetchState(model.model.country.uuid, 'states_z')"
                    )
                v-flex(xs12 sm4 v-if="Object.keys(model.model.country).length")
                    v-select(
                        v-model="model.model.state"
                        :items='states_z',
                        item-value="uuid",
                        item-text="name", 
                        :label='$t("Estado/Departamento/Provincia")', 
                        append-icon="keyboard_arrow_down", 
                        autocomplete
                        :filter="vFilter"
                        :return-object="true"
                        @input="cities_z=[],rfetchCity(model.model.state.uuid, 'cities_z')"
                    )
                v-flex(xs12 sm4 v-if="Object.keys(model.model.state).length")
                    v-select(
                        v-model="model.model.city"
                        :items='cities_z',
                        item-value="uuid",
                        item-text="name",
                        :label='$t("Ciudad/Municipio")',
                        append-icon="keyboard_arrow_down",
                        autocomplete
                        :filter="vFilter"
                        :return-object="true",
                        @click="model.model.zonei=-1"
                        @input="fetchZone(model.model)"
                    )
                template(v-if="zones.length")
                    v-flex(xs12 sm4 v-for="(zone, i) in zones")
                        v-select(
                            v-model="model.model.zones[i]"
                            :items='zone',
                            item-value="uuid",
                            item-text="name", 
                            :label='zone[0].division', 
                            append-icon="keyboard_arrow_down", 
                            autocomplete
                            :filter="vFilter"
                            @change="model.model.zonei=i"
                            :return-object="true"
                            @input="fetchZone(model.model, model.model.zones[i])"
                        )
            template(slot="list" slot-scope="item")
                | {{ item.item.country.name }} - {{ item.item.state.name }} - {{ item.item.city.name }} - {{ labelZones(item.item.zones) }}

        color-filter(
            v-model="value.colors_gender" 
            title="Colores por género"
            :schema="colorGenderSchema"
            :validation="validateGender"
        )
            template(slot="form" slot-scope="model")
                v-flex(xs12 sm4)
                    v-select(
                        v-model="model.model.gender"
                        :items='gender',
                        item-value="id",
                        item-text="text"
                        :label='$t("Género")',
                        append-icon="keyboard_arrow_down",
                        autocomplete
                        :return-object="true"
                    )
            template(slot="list" slot-scope="item")
                | {{ item.item.gender.text }}

        color-filter(
            v-model="value.colors_age"
            title="Colores por edades"
            :schema="colorAgeSchema"
            :validation="validateAge"
        )
            template(slot='form' slot-scope='model')
                v-flex(xs12 sm4)
                    v-text-field(:label="$t('Hasta')" v-model="model.model.gt" type="number")
            template(slot="list" slot-scope="item")
                | {{ $t('Hasta') }} {{ item.item.gt }}
        color-filter(
            v-model="value.colors_level"
            title="Colores por nivel"
            :schema="colorLevelSchema"
            :validation="validateLevel"
        )
            template(slot='form' slot-scope='model')
                v-flex(xs12 sm4)
                    v-text-field(:label="$t('Igual a')" v-model="model.model.eq" type="number")
            template(slot="list" slot-scope="item")
                | {{ $t('Igual a') }} {{ item.item.eq }}
        
        template(v-if="network")
            h3.mt-3 {{ $t('Gráficos formularios personalizados') }}
            v-flex(xs12)
                v-text-field(:label="$t('Etiqueta')" v-model="formlabel")
            v-flex(xs12)
                .btns.btns-right
                    v-btn(@click.native="addFormGraph" color="primary" small) 
                        | {{ $t('Agregar gráfico') }}
            template(v-for="(graph, g) in value.colors_form")
                color-filter(
                    v-model="graph.colors"
                    :schema="colorFormSchema"
                    :title="graph.label"
                    :validation="validateForm"
                )
                    template(slot='form' slot-scope='model')
                        v-btn(color="primary" small @click="value.colors_form.splice(g, 1)") {{ $t('Eliminar gráfico') }}
                        v-flex(xs12)
                            h3.my-0.mt-2 {{ $t('Preguntas') }}
                            v-select(
                                v-model="form"
                                :items='forms',
                                item-text="placeholder",
                                :label='$t("Pregunta")',
                                append-icon="keyboard_arrow_down",
                                autocomplete
                                :filter="vFilter"
                                :return-object="true"
                            )
                        v-flex(xs12 v-if="Object.keys(filterForm).length")
                            v-select(
                                v-model="option"
                                :items='filterForm',
                                item-text="option",
                                :label='$t("Respuesta")',
                                append-icon="keyboard_arrow_down",
                                autocomplete
                                :filter="vFilter"
                                multiple
                                :return-object="true"
                            )
                        v-flex(xs12)
                            .btns.btns-right
                                v-btn(@click.native="addQuestion(model)" color="primary" small) 
                                    | {{ $t('Agregar pregunta') }}
                        v-flex(xs12)
                            .table__overflow(v-if="model.model.conds.length")
                                table.datatable.table
                                    thead
                                        tr
                                            th(align="left" v-for="header in ['Pregunta', 'Respuesta/s', 'Opciones']") 
                                                span.subheading {{ $t(header) }}
                                    tbody
                                        tr(v-for="(cond, i) in model.model.conds")
                                            td(align="left")
                                                | {{ cond.form.placeholder }}
                                            td(align="left")
                                                | {{ labelOption(cond.option) }}
                                            td(align="left")
                                                v-icon.cur-p.primary--text(@click="model.model.conds.splice(i, 1)") close
                    template(slot="list" slot-scope="item")
                        template(v-for="cond in item.item.conds")
                            small.d-block {{ cond.form.placeholder }}
                            small.d-block.mb-2 {{ labelOption(cond.option) }}
</template>

<script>
import ColorFilter from 'components/colors/SetupFilter'
import fn from 'mixins/fn'
export default {
    mixins: [fn],
    components: {
        'color-filter': ColorFilter
    },
    props: {
        value: {
            type: Object,
            default: () => {
                return {}
            }
        },
        network: {
            type: Boolean,
            default: false
        },
        networkid: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            loadingBtn: false,
            colorStateSchema: {
                country : {},
                state   : {}
            },
            colorCitySchema: {
                country : {},
                state   : {},
                city    : {}
            },
            colorZoneSchema: {
                country : {},
                state   : {},
                city    : {},
                zones   : [],
                zonei   : -1
            },
            colorGenderSchema: {
                gender : {}
            },
            colorAgeSchema: {
                gt : 0
            },
            colorLevelSchema: {
                eq : 1
            },
            colorFormSchema: {
                conds : []
            },
            countries : [],
            states_s  : [],
            states_c  : [],
            states_z  : [],
            cities    : [],
            cities_z  : [],
            zones     : [],

            forms      : [],
            form       : {},
            option     : [],
            formgraphs : [],
            formlabel  : ''
        }
    },
    computed: {

        gender(){ 
            return [
                {
                    text: this.$t('Masculino'),
                    id: 1
                },
                {
                    text: this.$t('Femenino'),
                    id: 0
                },
                {
                    text: this.$t('Otro'),
                    id: 2
                }
            ]
        },
        filterForm(){

            let f = this.forms.find(c => c.uuid == this.form.uuid)
            return f != undefined ? f.options : []
        }
    },
    async created(){
        await this.fetchCountry()
        await this.fetchForms()
    },
    methods: {

        addQuestion(model){
            
            model.model.conds.push({
                form : {
                    uuid       : this.form.uuid,
                    placeholder: this.form.placeholder
                },
                option : this.option.map((o) => {
                    return {
                        uuid  : o.uuid,
                        option: o.option
                    }
                })
            })

            this.form   = {}
            this.option = []
        },

        fetchState(countryid, key='states_s'){

            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/state', {
                        params: {
                            countryid: countryid,
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this[key] = data.data
                        }

                        resolve()
                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },

        fetchCity(stateid, key='cities'){

            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/city', {
                        params: {
                            stateid: stateid,
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this[key] = data.data
                        }
                        resolve()
                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },

        fetchZone(model, item){

            this.zones = model.zonei == -1 ? [] : this.zones.slice(0, model.zonei + 1)
            
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/zone', {
                        params: {
                            cityid: model.city.uuid,
                            parent: model.zonei == -1 ? 0 : item.uuid,
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            if(data.data.length){
                                model.zones.push({})
                                this.zones.push(data.data)
                            }
                        }
                        resolve()
                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },

        async rfetchState(countryid, key = 'states_s'){
            await this.fetchState(countryid, key)
        },

        async rfetchCity(stateid, key = 'cities'){
            await this.fetchCity(stateid, key)
        },

        validateStates(model){
            
            return new Promise((resolve) => {

                if(!Object.keys(model.country).length){
                    resolve({
                        response : false,
                        message  : 'Seleccione un país'
                    })
                }

                if(!Object.keys(model.state).length){
                    resolve({
                        response : false,
                        message  : 'Seleccione un estado'
                    })
                }

                resolve({
                    response: true
                })
            })
        },

        validateCities(model){

            return new Promise((resolve) => {

                if(!Object.keys(model.country).length){
                    resolve({
                        response : false,
                        message  : 'Seleccione un país'
                    })
                }

                if(!Object.keys(model.state).length){
                    resolve({
                        response : false,
                        message  : 'Seleccione un estado'
                    })
                }

                if(!Object.keys(model.state).length){
                    resolve({
                        response : false,
                        message  : 'Seleccione una ciudad'
                    })
                }

                resolve({
                    response: true
                })
            })
        },

        validateZones(model){

            return new Promise((resolve) => {

                if(!Object.keys(model.country).length){
                    resolve({
                        response : false,
                        message  : 'Seleccione un país'
                    })
                }

                if(!Object.keys(model.state).length){
                    resolve({
                        response : false,
                        message  : 'Seleccione un estado'
                    })
                }

                if(!Object.keys(model.state).length){
                    resolve({
                        response : false,
                        message  : 'Seleccione una ciudad'
                    })
                }

                resolve({
                    response: true
                })
            })
        },

        validateGender(model){

            return new Promise((resolve) => {

                if(!Object.keys(model.gender).length){
                    resolve({
                        response : false,
                        message  : 'Seleccione un género'
                    })
                }
                
                resolve({
                    response: true
                })
            })
        },

        validateAge(model){

            return new Promise((resolve) => {
                if(isNaN(model.gt) || model.gt < 0){
                    resolve({
                        response : false,
                        message  : 'El campo hasta debe ser un número mayor o igual a cero'
                    })
                }
                
                resolve({
                    response: true
                })
            })
        },

        validateLevel(model){

            return new Promise((resolve) => {
                if(isNaN(model.eq) || model.eq < 0){
                    resolve({
                        response : false,
                        message  : 'El campo hasta debe ser un número mayor a cero'
                    })
                }
                resolve({
                    response: true
                })
            })
        },

        validateForm(model){

            return new Promise((resolve) => {

                if(!model.conds.length){
                    resolve({
                        response : false,
                        message  : 'Seleccione al menos una pregunta'
                    })
                }

                resolve({
                    response: true
                })
            })
        },

        fetchCountry(){

            this.$api(this, (xhr) => {
                xhr.get('/country', {
                    params: {
                        filter: JSON.stringify({
                            status: 1
                        })
                    }
                }).then((r) => {

                    let data = r.data
                    if(data.response){
                        this.countries = data.data
                    }
                }).catch(() => {})
            })
        },

        labelZones(zones){
            return zones.map((z) => z.name).join(' - ')
        },

        labelOption(option){
            return option.map((o) => o.option).join(', ')
        },

        fetchForms(){
            
            return new Promise((resolve) => {

                 if(!this.network){
                    return resolve()
                }

                this.$api(this, (xhr) => {
                    xhr.get('/network/form', {
                        params: { networkid: this.networkid }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            
                            let form = data.data
                            
                            form.custom = form.custom.filter((c) => [2,3,4].includes(c.type)).map((c) => {
                                return {
                                    placeholder: c.placeholder,
                                    options    : c.options,
                                    uuid       : c.uuid
                                }
                            })

                            form = form.custom
                            this.forms = form

                            resolve()

                        }else{
                            resolve()
                        }

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },

        addFormGraph(){

            if(!this.formlabel.trim().length){
                return
            }

            this.value.colors_form.push({
                label  : this.formlabel,
                colors : []
            })

            this.formlabel = ''
        }
    }
}
</script>