<template lang="pug">
    div
        v-snackbar(
        :timeout="3000"
        :top="true"
        :right="true"
        :color="saveColor"
        v-model="saveMsj")
            | {{ saveText }}
            v-btn(flat dark @click.native="saveMsj = false") {{ $t('cerrar') }}
        h3.mt-3 {{$t(title)}}
        v-layout(row wrap)
            v-flex(xs12 sm4)
                v-text-field(:label="$t('Mayor o igual a')" v-model="color.gt" type="number")
            v-flex(xs12 sm4)
                v-dialog(ref="colorPicker" v-model="colorDialog" lazy full-width width='290px' :return-value.sync='color.color')
                    template(slot="activator")
                        v-text-field(:label="$t('Color')" v-model="color.color.hex" type="text" readonly hide-details)
                            template(slot="label")
                                | {{ $t('Color') }}&nbsp;
                                div(:style="'background:' + color.color.hex + ';width:10px;height:10px;border-radius:30px;display:inline-block;vertical-align-middle;border:thin solid rgb(232, 232, 232);'")
                    v-card
                        v-card-title
                            .btns.btns-right
                                v-btn(icon color="secondary" small @click.native="colorDialog=false")
                                    v-icon close
                            h5.full-width {{ $t('Seleccione un color') }}
                        v-card-text
                            chrome-picker(v-model="color.color")
                        v-card-actions
                            .btns.btns-right
                                v-btn(color="secondary" small @click="colorDialog=false") {{$t('Cancelar')}}
                                v-btn(color="secondary" small @click="$refs.colorPicker.save(color.color)") {{$t('Guardar')}}
            v-flex(xs12)
                .btns.btns-right
                    v-btn(@click.native="addColor" :loading="loadingColor" color="primary" small) 
                        template(v-if="color.i == -1") {{$t('Agregar color')}}
                        template(v-else) {{$t('Guardar color')}}
            v-flex(xs12)
                .table__overflow(v-if="value.length")
                    table.datatable.table
                        thead
                            tr
                                th(align="left" v-for="header in headers") 
                                    span.subheading {{ $t(header.text) }}
                        draggable(tag="tbody" :list.sync="value" :options="colorsDrag")
                            tr.color--drag(v-for="(color, i) in value")
                                td(align="left")
                                    v-icon.color--handle.cur-p.primary--text drag_indicator
                                td(align="left")
                                    div(:style="'background:' + color.hex + ';width:20px;height:20px;border-radius:30px;display:inline-block;vertical-align-middle;border:thin solid rgb(232, 232, 232);'")
                                td(align="left") {{ color.gt }}
                                td(align="left")
                                    v-icon.cur-p.primary--text(@click="editColor(color, i)") edit
                                    v-icon.cur-p.primary--text(@click="value.splice(i, 1)") close
</template>

<script>

    import { Chrome } from 'vue-color'
    import draggable from 'vuedraggable'

    export default {
        components: {
            'chrome-picker' : Chrome,
            draggable
        },
        props: {
            value: Array,
            title: {
                type: String,
                default: 'Colores de distribución de referidos'
            }
        },
        data(){
            return {
                colorSchema: {
                    color: {
                        hex: ''
                    },
                    gt : 0,
                    i  : -1
                },
                loadingColor: false,
                color: {},
                colorDialog: false,
                headers: [
                    {
                        text: ''
                    },
                    {
                        text: 'Color'
                    },
                    {
                        text: 'Mayor o igual a'
                    },
                    {
                        text: 'Opciones'
                    }
                ],
                colorsDrag: {
                    group    : 'colors',
                    draggable: '.color--drag',
                    handle   : '.color--handle'
                },
                saveMsj   : false,
                saveColor : '',
                saveText  : '',
            }
        },
        created(){
            this.color = this._.cloneDeep(this.colorSchema)
        },
        methods: {
            addColor(){

                try{

                    if(isNaN(this.color.gt) || this.color.gt < 0){
                        throw 'El campo mayor o igual que debe ser un número mayor o igual a cero'
                    }

                    if(!this.color.color.hex.trim().length){
                        throw 'El campo color es requerido'
                    }

                    let color = {
                        gt   : this.color.gt,
                        hex  : this.color.color.hex
                    }

                    if(this.color.i != -1){
                        this.value.splice(this.color.i, 1, color)
                    }else{
                        this.value.push(color)
                    }

                    this.color = this._.cloneDeep(this.colorSchema)

                }catch(e){

                    this.saveMsj = true
                    this.saveColor = 'error'
                    this.saveText = this.$t(e)
                }
            },

            editColor(color, i){
                this.color = {
                    color: {
                        hex: color.hex
                    },
                    gt: color.gt,
                    i : i
                }
            }
        }
    }
</script>